import {
  EQUIVALENCES_TOOLTIP_CARBON,
  EQUIVALENCES_TOOLTIP_GAS,
  EQUIVALENCES_TOOLTIP_HOME,
  EQUIVALENCES_TOOLTIP_TITLE,
  EQUIVALENCES_TOOLTIP_TREES
} from './constants';

export const getCardIconName = (cardName: string) => {
  switch (cardName) {
    case 'Cars':
      return 'car-solid';

    case 'Trees':
      return 'pagelines-brands';

    case 'Homes':
      return 'house-chimney-solid';

    case 'Natural Gas':
      return 'natural-gas-outlined';

    case 'Carbon':
      return 'industry-solid';

    case 'Gas':
      return 'gas-pump-solid';

    case 'Water':
      return 'water-outlined';

    case 'Methane':
      return 'methane-outlined';

    case 'Carbon Dioxide':
      return 'carbon-dioxide';

    case 'Flow Meters':
      return 'flow-meters';

    default:
      return '';
  }
};

export const getTooltipMessage = (equivalenceName: string) => {
  switch (equivalenceName.toLowerCase()) {
    case 'trees':
      return EQUIVALENCES_TOOLTIP_TREES;
    case 'carbon':
      return EQUIVALENCES_TOOLTIP_CARBON;
    case 'gas':
      return EQUIVALENCES_TOOLTIP_GAS;
    case 'homes':
      return EQUIVALENCES_TOOLTIP_HOME;
    case 'main-title':
      return EQUIVALENCES_TOOLTIP_TITLE;
    default:
      return '';
  }
};
