import { STORE_VARIABLE } from '../enums/sessiontorage';
import { statusErrors, stringErrors } from './errors';

export function GetFriendlyStatusError(error: number): string {
  if (statusErrors[error]) {
    return statusErrors[error];
  }
  return stringErrors.UNKNOWN_ERROR;
}

export function GetFriendlyError(error: string): string {
  if (stringErrors[error]) {
    return stringErrors[error];
  }
  return stringErrors.UNKNOWN_ERROR;
}

export const rankColors = ['warning.300', 'warning.900', 'error.300', 'error.900'];

export function isNew(createdAt?: Date, daysThreshold = 3): boolean {
  if (!createdAt) {
    return false;
  }
  const millisDiff = new Date().getTime() - createdAt.getTime();
  return millisDiff / 1000 / 60 / 60 / 24 < daysThreshold;
}

export function millisDiff(date1: Date, date2 = new Date()): number {
  return date2.getTime() - date1.getTime();
}

export const clientPathQueryParamBuilder = (value: string, property = STORE_VARIABLE.SITE_NAME, notFirst = false) =>
  `${notFirst ? '&' : '?'}${property}=${value}`;

export const getGraphPageFooterAssetsPath = (config: any) => {
  const url = process.env.REACT_APP_API_URL as string;
  const logoPath = config?.footerLogo?.graphPage?.logo || '';
  return logoPath.length ? `${url}${logoPath}` : undefined;
};

// Turns kWh into MWh/GWh if it's needed
export const kilowattsFormatter = (value: number) => {
  if (value >= 10000000) {
    return { value: value / 1000000, unit: 'GWh' };
  }
  if (value >= 10000) {
    return { value: value / 1000, unit: 'MWh' };
  }
  return { value: value, unit: 'kWh' };
};
